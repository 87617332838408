.buttonWrapper {
  border-radius: 999px;
  padding: 10px;
  min-width: 190px;
  font-weight: 600;
  font-size: 13px;
  transition: 0.1s;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}