* {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
}

h1,h2,h3,p {
  line-height: 1.2;
}

h1 {
  font-size: 64px;
  font-weight: 700;
}

h2 {
  font-size: 29px;
  font-weight: 400;
}

h3 {
  font-size: 28px;
  text-align: center;
  font-weight: 600;
}

h4 {
  font-size: 24px;
  font-weight: 600;
}

h5 {
  font-size: 17px;
  font-weight: 600;
}

p {
  font-size: 20px;
  line-height: 30px;
}