.bulletClass {
  width: 6px;
  height: 6px;
  background-color: #000;
  cursor: pointer;
}

.activeBulletClass {
  width: 6px;
  height: 6px;
  background-color: rgb(191, 191, 191);
}

.blockRenderer {
  font-weight: 400;
  font-size:18px;

  p {
    font-size: 16px;
    line-height: 25px;
  }
}