a {
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  transition: 0.3s;

  &:hover {
    color: #0094da;
    transition: 0.1s;
  }
}
